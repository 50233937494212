.accounts__delete-button.ant-col {
  display: flex;
  align-items: flex-end;
}

.accounts__delete-button .ant-form-item {
  flex: 0 0 100%;
}

.accounts__delete-button button.ant-btn:hover {
  background-color: #f5222d;
  border-color: #f5222d;
}

.accounts__add-button.ant-btn,
.accounts__add-button.ant-btn:focus {
  background-color: #52c41a;
  border-color: #52c41a;
}

.accounts__add-button.ant-btn:hover {
  background-color: #5ec72b;
  border-color: #5ec72b;
}

.settings__tab-body {
  /* height: 60vh; */
  width: 100%;
  position: absolute;
  overflow: hidden auto;
}

.settings__tabs {
  height: 100%;
}

.settings__tab-body .ant-divider.ant-divider-with-text {
  margin: 0 0 1em 0;
}

.ant-tabs.settings__tabs>.ant-tabs-nav {
  margin-bottom: 0;
}

.settings__modal {
  max-width: 750px !important;
}
