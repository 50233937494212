/*Toggles*/

form#settings {
  position: absolute;
  z-index: 1;
  top: 0;
  background: white;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 4px 24px 24px 24px;
}
