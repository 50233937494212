.data-panel {
  position: relative;
}

div#data-panel-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.data-panel__modal .ant-modal-header,
.ant-modal-close,
.ant-modal-footer {
  display: none;
}


.data-panel__modal .ant-modal-content {
  min-height: 752px;
  display: flex;
  flex-direction: column;
}

.data-panel__modal .ant-modal-body {
  padding: 0;
  flex: 1;
}

.data-panel__modal .ant-modal-content .softphone-container {
  right: 0;
  bottom: 0;
}

.data-panel__modal .ant-tabs-content-holder {
  position: relative;
}


.data-panel__modal-phone {
  display: flex;

}

.data-panel__modal-phone .phone-container {
  justify-content: end;
}

.data-panel__modal-phone .phone {
  flex: 1;
}

.data-panel__modal-content {
  position: relative;
  width: 100%;
  flex: 1
}

.data-panel__modal .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: row;
}
