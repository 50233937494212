.contacts-search__container {
  /* background-color: white;
    position: absolute;
    width: 100%;
    height: 100%;
    top: -13%;
    left: -105%;
    border: 1px solid #ECF1F3; 
    border-radius: 12px;
    overflow: hidden; */
  /* background-color: white; */
  background-color: #ecf1f3;
  position: absolute;
  width: 100%;
  height: 138%;
  top: -31%;
  left: -107%;
  border: 1px solid #ecf1f3;
  border-radius: 12px;
  overflow: hidden;
  /* padding: 25% 0 32%; */
}

.phone__contacts-container .contacts-search {
  overflow: auto scroll;
  padding-top: 4px;
  width: 100%;
  height: 100%;
  background: white;
  border: 1px solid #ecf1f3;
}

.contacts-search .ant-list-item .ant-list-item-meta-title {
  margin-bottom: 0;
}

.contacts-search .ant-badge-status-dot {
  top: 0;
}

.contacts-search .contacts-search__title {
  font-weight: 600;
  font-size: 0.625rem;
}

.contacts-search .contacts-search__title .ant-badge-status-text {
  margin-left: 10px;
}

.contacts-search .contacts-search__number {
  color: #74889b;
  font-size: 0.563rem;
}

.contacts-search .contacts-search__group-title {
  color: #74889b;
  font-size: 0.625rem;
  margin-bottom: 2px;
  padding: 6px 14px 0 14px;
}

.contacts-search .contacts-search__group-title:not(:first-child) {
  border-top: 1px solid #f4f8fd;
}

.contacts-search h4.ant-list-item-meta-title {
  font-size: 0.75rem;
  font-weight: 500;
  text-align: start;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.contacts-search .ant-list-item-meta > .ant-list-item-meta-content {
  display: flex;
}
