.active-call {
  padding: 14px;
  border-bottom: 1px solid #ecf1f3;
}

.active-call .ant-btn {
  width: 40px !important;
}

.active-call .ant-btn.decline-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.active-call button.ant-btn svg {
  width: 18px;
  height: 18px;
}

.active-call_decline_container {
  display: flex;
  align-items: center;
  gap: 15px;
}

.active-call__heading-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 10px;
}

.active-call__video_container {
  visibility: hidden;
  opacity: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 0;
}
.active-call__video_container video {
  max-width: 100% !important;
}

.active-call__video_container.active {
  visibility: visible;
  opacity: 1;
  height: 100%;
  padding-bottom: 10px;
}

.active-call__heading {
  font-weight: 700;
  font-size: 1rem;
}

.signal {
  display: flex;
  gap: 2px;
  align-items: baseline;
}

.signal__block {
  width: 5px;
  background: rgba(255, 255, 255, 0.3);
  border-radius: 1px;
}

.signal__block.active {
  background: #fff;
}

.signal__block:nth-child(1) {
  height: 5px;
}

.signal__block:nth-child(2) {
  height: 10px;
}

.signal__block:nth-child(3) {
  height: 15px;
}

.signal__block:nth-child(4) {
  height: 20px;
}

.signal__block:nth-child(5) {
  height: 25px;
}

.active-call__caller-container {
  font-size: 0.75rem;
}

.active-call__caller-text {
  margin: 0 0 10px 0;
  display: flex;
  gap: 4px;
  align-items: center;
}

.active-call__caller-text > * {
  display: inline-block;
}

.active-call__caller-text > span {
  color: white;
}

.active-call__caller-text > span svg {
  width: 0.75rem;
  height: 0.75rem;
  vertical-align: middle;
}

.active-call__caller-text > span:not(:last-child) {
  margin-right: 6px;
}

.active-call__caller-text > .active-call__caller-text__number {
  color: #74889b;
}

.active-call__timer {
  display: flex;
  line-height: 1;
  align-items: center;
}

.active-call__timer > span {
  color: #52c41a;
  font-size: 1rem;
  /*line-height: 1;*/
}

.active-call__timer.hold > span {
  color: #f5222d;
}

.ant-tag.active-call__hold-tag {
  margin-left: 8px;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0 6px;
  display: inline-flex;
  align-items: center;
}

.active-call__hold-tag > svg {
  color: #1e2a33;
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 4px;
}

.active-call__hold-tag span {
  color: #1e2a33;
}

.decline-button,
.answer-button,
.toggle-button,
.active {
  border: none !important;
}

.answer-button:hover {
  background-color: #49b613 !important;
}

.decline-button:hover {
  background-color: #eb4548 !important;
}
.phone__toggles .toggle-button .ant-btn {
  border-color: transparent;
}

.phone__toggles_accept_row {
  gap: 10px;
}

.phone__toggles_accept_row button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone__toggles_accept_row button svg {
  width: auto !important;
  height: auto !important;
}
