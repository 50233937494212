/*Dialpad.tsx*/

.phone__dialpad {
  align-items: stretch;
  margin-bottom: 10px;
}

.phone .phone__dialpad .dialpad__digit-button {
  flex: 1;
  padding: 8px 24px;
  margin: 5px;
  font-size: 0.875rem;
  border-color: #fff;
}

.phone .phone__dialpad .dialpad__digit-button:hover {
  border-color: #40a9ff;
}

.phone .phone__dialpad .dialpad__digit-button:focus-visible {
  border-color: #0d92d0;
}

.dialpad__row .dialpad__digit-button:first-child {
  margin-left: 0;
}

.dialpad__row .dialpad__digit-button:last-child {
  margin-right: 0;
}

.dialpad__row:first-child .dialpad__digit-button {
  margin-top: 0;
}

.dialpad__row:last-child .dialpad__digit-button {
  margin-bottom: 0;
}
