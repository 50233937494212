.calls-list-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
}

.calls-list__header {
  justify-content: space-between;
  padding: 14px 14px 10px 14px;
  align-items: center;
  background-color: #ecf1f3;
  border-bottom: 1px solid #74889b1a;
}

.calls-list__header svg {
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.calls-list__header h4 {
  margin-bottom: 0;
}

.calls-list__header .search .ant-btn > .anticon > svg {
  color: #1890ff;
}

.calls-list__header__left {
  align-items: center;
}

.calls-list__header__left > .ant-btn {
  width: unset;
  height: unset;
  margin-right: 10px;
}

.calls-list-container .calls-list {
  padding-top: 10px;
}

.calls-list {
  overflow: hidden auto;
}

.calls-list svg {
  width: 18px;
  height: 18px;
}

.calls-list__icon.answered {
  color: #52c41a;
}

.calls-list__icon.missed {
  color: #ff4d4f;
}

.calls-list__icon.outgoing {
  color: #40a9ff;
}

.calls-list .ant-list-item-meta-avatar {
  display: flex;
}

.calls-list .ant-list-item-meta-title {
  font-size: 0.75rem;
  margin-bottom: 0;
  line-height: 0;
}

.calls-list .ant-list-item-meta-title > div {
  line-height: normal;
}

.calls-list .ant-list-item-meta-description {
  font-size: 0.625rem;
  color: #74889b;
  align-items: flex-end;
}

.calls-list .ant-list-item-meta-title .anticon-info-circle > svg {
  width: 10px;
  height: 10px;
  color: #1890ff;
}

.calls-list .ant-list-item-meta {
  align-items: center;
}

.calls-list .ant-list-item-meta-content {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

.ant-list-items > .ant-btn {
  height: unset;
  border-radius: unset;
  border: unset;
  padding: 0 14px;
}