.data-panel-switch-button {
  background: #1E2A33 !important;
  border-color: #1E2A33 !important;
  color: #FFFFFF !important;
  width: 140px;
  height: 30px !important;

  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 10px;



  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;

  transform: rotate(-90deg);

  position: absolute !important;
  left: -86px !important;
  top: 50%
}

.data-panel-switch-button:hover {
  background: #404d57 !important;
  border-color: #1E2A33 !important;
}
