.phone-queues {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background: white;
  display: flex;
  flex-direction: column;
}

.phone-queues-container {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  overflow: hidden auto;
  gap: 5px;
}

.phone-queues-item {
  cursor: pointer;
  padding: 10px 15px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.phone-queues-item:hover {
  background-color: #3174e60d;
}